<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厦门公安局集美分局王局长一行莅临福建历思司法鉴定所调研</div>
      <p class="time">2018.07.20</p>
      <p class="space_content">
        近日，厦门市公安局集美分局局长王嘉兴、副局长王光锋、刑侦大队副大队长薛宝强等领导莅临福建历思司法鉴定所（以下简称“历思”）进行调研。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/14-1.png')">
      </div>

      <p class="space_content">
        思目前已获得的司法鉴定资质有
        <span style="font-weight: 600">17 大项</span>
        ，基本覆盖了当前司法鉴定的主要领域，能够提供优质的一站式物证技术融合服务。目前在环境物证鉴定、文书及痕迹检验、文书形成时间、声像资料、毒品毒物鉴定、文物艺术品鉴定等方面，检验技术已达国内先进水平；服务质量得到公、检、法、司等部门的充分认可。
      </p>
      <p class="space_content">
        历思长期承担厦门市酒精检测的主要任务，还承担了福建省主要涉毒案件鉴定工作，所提供的毛发检测、血液、尿液、唾液、毒情监测等综合物证技术服务深受欢迎。
      </p>
      <p class="space_content">
        历思以规范求发展，重视设备投入与质量管理，为保障技术研发与服务能力，历思创建了第三方大型物证中心，拥有1100平方米技术科研中心以及1500平方米国家标准实验室，实验室洁净区达到万级标准，全面配置多专业高端检验设备，具备了进行跨专业综合研究与技术实施的良好基础条件。并且在科技研发、自主创新和人才培养方面进行了大量投入。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/14-2.png')">
      </div>

      <p class="space_content">
        王嘉兴局长一行参观了历思物证中心实验室，详细了解了毒品检验鉴定、酒精检验鉴定等工作开展的情况。在双方座谈会上，王嘉兴局长高度评价了历思在司法鉴定领域所取得的成就，对历思的实验室建设、技术创新、人才梯队培养等方面给予充分的肯定。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/14-3.png')">
      </div>

      <p class="space_content">
        王嘉兴局长指出，司法鉴定与司法鉴定物证技术服务可为犯罪侦查与案件办理提供重要的证据，社会化物证技术服务可为公安部门提供更加科学高效的帮助。
      </p>
      <p class="space_content">
        王嘉兴局长非常赞赏历思对核心技术的自主创新，期待能够创造更多物证技术的科研成果，并提出双方今后可开展更深层次的合作。
      </p>




    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/13'"><p class="ellipsis1">涉嫌违法污染环境 — 厦门海沧非法炼油厂被端！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/15'"><p class="ellipsis1">历思案件被评选上了中国法律服务网，被司法行政（法律服务）收录为典型案例啦！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
